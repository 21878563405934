[data-live-name-value="Messenger"] {
  height: 75dvh;
  overflow: hidden auto;
  padding: 0 0.5rem;
  scrollbar-width: thin;

  .mce-tinymce {
    iframe {
      max-height: 20dvh;
    }
  }
}

.message-col {
  .message {
    border-radius: .25rem;

    .message-content {
      :last-child {
        margin-bottom: 0;
      }
    }
  }

  .bg-right {
    background-color: #f9dac2;

    &.unseen {
      border-right: 5px solid #ed6f10;
    }
  }

  .bg-left {
    background-color: #d6e49b;

    &.unseen {
      border-left: 5px solid #8aa344;
    }
  }

  .message_image {
    width: 150px;
    word-break: break-all;

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    i {
      transition: opacity 0.3s ease-in-out;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      background-size: contain;
    }

    &:hover {
      i {
        opacity: 0.5 !important;
      }
    }
  }

  .xsmall {
    font-size: 0.6rem;
  }
}

.badge {
  background-color: #d9d9d9;
  font-weight: normal;
}

#messages_file_preview_container {
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .fileupload-remove-item {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .file-preview,
  .filename {
    --size: 100px;
  }

  .file-preview {
    width: var(--size);
    height: var(--size);
    overflow: hidden;
  }

  .filename {
    width: var(--size);
    display: block;
    word-wrap: break-word;
  }
}