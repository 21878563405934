[data-live-name-value=Messenger] {
  height: 75dvh;
  overflow: hidden auto;
  padding: 0 0.5rem;
  scrollbar-width: thin;
}
[data-live-name-value=Messenger] .mce-tinymce iframe {
  max-height: 20dvh;
}

.message-col .message {
  border-radius: 0.25rem;
}
.message-col .message .message-content :last-child {
  margin-bottom: 0;
}
.message-col .bg-right {
  background-color: #f9dac2;
}
.message-col .bg-right.unseen {
  border-right: 5px solid #ed6f10;
}
.message-col .bg-left {
  background-color: #d6e49b;
}
.message-col .bg-left.unseen {
  border-left: 5px solid #8aa344;
}
.message-col .message_image {
  width: 150px;
  word-break: break-all;
}
.message-col .message_image a {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.message-col .message_image i {
  transition: opacity 0.3s ease-in-out;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-size: contain;
}
.message-col .message_image:hover i {
  opacity: 0.5 !important;
}
.message-col .xsmall {
  font-size: 0.6rem;
}

.badge {
  background-color: #d9d9d9;
  font-weight: normal;
}

#messages_file_preview_container img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
#messages_file_preview_container .fileupload-remove-item {
  position: absolute;
  top: 5px;
  right: 5px;
}
#messages_file_preview_container .file-preview,
#messages_file_preview_container .filename {
  --size: 100px;
}
#messages_file_preview_container .file-preview {
  width: var(--size);
  height: var(--size);
  overflow: hidden;
}
#messages_file_preview_container .filename {
  width: var(--size);
  display: block;
  word-wrap: break-word;
}